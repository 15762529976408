var riot = require('riot');
module.exports = 
riot.tag2('postcode-search', '<div class="col-xs-12"> <form onsubmit="return false" class="form-horizontal"> <div class="form-group"> <div class="input-group"> <input type="text" name="postcode" placeholder="Enter your postcode here for more information about your regional team" class="input-lg form-control pcode_input"><span class="pcode_submit input-group-addon btn btn-default btn-arrow">Submit</span> </div> </div> </form> </div>', '', '', function(opts) {
this.on('mount', (function(_this) {
  return function() {
    var btn, input;
    btn = document.querySelector('.pcode_submit');
    input = document.querySelector('.pcode_input');
    if (btn) {
      btn.addEventListener('click', function() {
        return _this.submitform();
      });
    }
    return input.addEventListener('keyup', function(e) {
      if (e.keyCode === 13) {
        return _this.submitform();
      }
    });
  };
})(this));

this.submitform = (function(_this) {
  return function() {
    var input, match, postcode, regex;
    input = document.querySelector('input[name="postcode"]');
    regex = new RegExp(/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/i);
    match = input.value.match(regex);
    if (match) {
      postcode = encodeURIComponent(input.value);
      return _this.jsonp(postcode, function(data) {
        return _this.mapdata(data);
      });
    }
  };
})(this);

this.mapdata = (function(_this) {
  return function(data) {
    if (data.result.admin_district) {
      return window.location.href = window.location.pathname + '?t=' + data.result.admin_district.toLowerCase();
    }
  };
})(this);

this.jsonp = function(postcode, callback) {
  var callbackName, script;
  callbackName = 'jsonp_callback_' + Math.round(100000 * Math.random());
  window[callbackName] = function(data) {
    console.log('callback');
    delete window[callbackName];
    document.body.removeChild(script);
    callback(data);
  };
  script = document.createElement('script');
  script.src = 'https://api.postcodes.io/postcodes/' + postcode + '?callback=' + callbackName;
  document.body.appendChild(script);
};
});