require('./raw')
riot.tag2('tabs', '<div class="tabs"> <ul class="nav nav-tabs"> <li each="{tab, i in tabs}" onclick="{activate}" class="{active: tab.active, disabled: tab.disabled, first: i == 0, last: parent.tabs.length-1 == i}"><a if="{tab.opts.heading &amp;&amp; !tab.heading}" class="heading">{tab.opts.heading}</a><a if="{tab.heading}" riot-tag="raw" content="{tab.heading}" class="heading"></a></li> </ul> <div class="tab-content"><yield></yield></div> </div>', '', '', function(opts) {
this.tabs = [];

this.onopen = opts.onopen;

if (this.tags.tab != null) {
  this.tabs = this.tags.tab;
  if (!Array.isArray(this.tabs)) {
    this.tabs = [this.tabs];
  }
}

this.on('mount', function() {
  var activeTab;
  activeTab = false;
  this.tabs.forEach(function(tab) {
    var tabHeading;
    tabHeading = tab.tags['tab-heading'];
    if (tabHeading && Object.prototype.toString.call(tabHeading) !== '[object Array]') {
      tab.heading = tabHeading.root.innerHTML;
    }
    if (activeTab) {
      tab.active = false;
    }
    if (tab.active) {
      activeTab = true;
    }
    return setTimeout(function() {
      var ref;
      return tabHeading != null ? (ref = tabHeading.root) != null ? typeof ref.remove === "function" ? ref.remove() : void 0 : void 0 : void 0;
    });
  });
  return this.update();
});

this.activate = function(e) {
  var tab;
  tab = e.item.tab;
  if (!tab.disabled) {
    this.deselectTabs();
    if (this.onopen) {
      this.onopen(tab);
    }
    return tab.active = true;
  }
};

this.deselectTabs = function() {
  return this.tabs.forEach(function(tab) {
    return tab.active = false;
  });
};
});
riot.tag2('tab', '<div show="{active}" class="tab-pane {active: active}"><yield></yield></div>', '', '', function(opts) {
this.active = opts.active;

this.disabled = opts.disabled;
});
riot.tag2('tab-heading', '<yield></yield>', '', '', function(opts) {
});