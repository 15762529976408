var riot = require('riot');
module.exports = 
riot.tag2('recaptcha', '<div id="{opts.name}"></div>', '', '', function(opts) {
this.on('mount', function() {
  return setTimeout((function(_this) {
    return function() {
      return _this.recaptcha_id = grecaptcha.render(opts.name, {
        sitekey: opts.key,
        theme: "light",
        type: "image",
        callback: _this.callback,
        'expired-callback': _this.reset
      });
    };
  })(this), 250);
});

this.reset = (function(_this) {
  return function() {
    return grecaptcha.reset(_this.recaptcha_id);
  };
})(this);

this.callback = (function(_this) {
  return function(data) {
    if (_this.opts.onupdate) {
      return _this.opts.onupdate(data);
    }
  };
})(this);
});